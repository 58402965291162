import React from 'react';
import dynamic from 'next/dynamic';

const Home = () => {
  const Player = dynamic(import('@/pages/live/components/btn-like'), { ssr: false })

  return <Player />;
};

export default Home;
